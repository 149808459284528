import request from "@/utils/https";

/**
 * 获取班级下的分组列表
 * @param {String} classId
 * @return {AxiosPromise}
 */
export function getGroupList(classId) {
  return request({
    url: '/userGroup/findList',
    method: 'POST',
    data: {
      classId
    }
  })
}

/**
 * 新建分组
 * @param {String} name
 * @param {String} classId
 * @return {AxiosPromise}
 */
export function createGroup(name, classId) {
  return request({
    url: '/userGroup/insert',
    method: 'POST',
    data: {
      name,
      classId
    }
  })
}

/**
 * 重命名分组
 * @param {String} id 分组ID
 * @param {String} name
 * @return {AxiosPromise}
 */
export function renameGroup(id, name) {
  return request({
    url: '/userGroup/update',
    method: 'POST',
    data: {
      id,
      name
    }
  })
}

/**
 * 删除分组
 * @param {String} id
 * @return {AxiosPromise}
 */
export function deleteGroup(id) {
  return request({
    url: '/userGroup/delete',
    method: 'POST',
    data: {
      id
    }
  })
}

/**
 * 获取分组底下的学生
 * @param {String} userGroupId
 * @return {AxiosPromise}
 */
export function getStudentsForGroup(userGroupId) {
  return request({
    url: '/userGroupRelation/studentList',
    method: 'POST',
    data: {userGroupId}
  })
}

/**
 * 向分组内添加学生
 * @param {String} studentIds 逗号分隔
 * @param {String} userGroupId
 * @return {AxiosPromise}
 */
export function addStudentsToGroup(studentIds, userGroupId) {
  return request({
    url: '/userGroupRelation/addStudent',
    method: 'POST',
    data: {
      studentIds,
      userGroupId
    }
  })
}

/**
 * 移除分组内的学生
 * @param {String} ids 逗号分隔
 * @return {AxiosPromise}
 */
export function removeStudentsForGroup(ids) {
  return request({
    url: '/userGroupRelation/removeStudent',
    method: 'POST',
    data: {
      ids
    }
  })
}

/**
 * 加入一个或多个分组
 * @param {String} classId
 * @param {String} studentId
 * @param {String} groupIds 分组ID，逗号分隔
 * @return {AxiosPromise}
 */
export function joinGroups(classId, studentId, groupIds) {
  return request({
    url: '/userGroup/changeUserGroup',
    method: 'POST',
    data: {
      studentIds: studentId,
      groupIds,
      classId
    }
  })
}
