<template>
  <el-dialog
    class="pointsModal"
    width="300px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
  >
    <div class="content">
      <div class="icon">
        <img src="../assets/points-icon.png" alt="icon">
      </div>
      <div class="mb-6">
        <span class="rewardPoints">+{{rewardPoints}}</span>积分
      </div>
      <div class="fs-17">{{rewardPointsMsg}}</div>
    </div>
    <div class="footer">
      <div class="tips">可前往教师端APP查看积分</div>
      <el-button class="btn" type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'pointsModal',
  props: ['list', 'index'],
  data () {
    return {
      visible: false,
      rewardPoints: 0,
      rewardPointsMsg: ''
    }
  },
  methods: {
    visibleChange ({rewardPoints, rewardPointsMsg}) {
      if (rewardPoints) {
        this.rewardPoints = rewardPoints
        this.rewardPointsMsg = rewardPointsMsg
      }
      this.visible = !this.visible
    },
    handleClose () {
      this.$emit('handleClose')
    },
    confirm () {
      this.visible = false
      this.$emit('confirmCallback')
    }
  }
}
</script>

<style>
  .pointsModal .el-dialog__header{
    padding: 0;
  }
</style>
<style lang="scss" scoped>
  .content {
    text-align: center;
    font-size: 20px;
    .icon {
      width: 180px;
      height: 71px;
      margin: 0 auto 6px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rewardPoints {
      display: inline-block;
      margin-right: 6px;
      color: #FF7C53;
    }
    .fs-17 {
      font-size: 17px;
    }
    .mb-6 {
      margin-bottom: 6px;
    }
  }
  .footer {
    margin-top: 40px;
    text-align: center;
    .tips {
      font-size: 14px;
      color: #999999;
    }
    .btn {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      width: 250px;
      height: 46px;
      margin-top: 10px;
      font-size: 17px;
    }
  }
</style>
