<template>
  <div class="work-submit" v-loading="loading">
    <Header></Header>
    <article class="work-submit-container">
      <section class="homework-content">
        <div class="homework-content-nav">
          <h2 class="nav-title">作业内容</h2>
          <router-link :to="{name: 'assignWork'}" replace class="continue-btn">
            <div>继续添加作业</div>
            <div class="icon"></div>
          </router-link>
        </div>
        <div class="homework-content-list-view">
          <div class="list-item" v-for="item in $store.state.selectedWorkList" :key="item.id">
            <div class="list-content">
              <div class="left">
                <div class="flex">
                  <div class="name" v-html="item.name"></div>
                  <ul class="tabs" v-if="item.typeList">
                    <template v-for="type in item.typeList">
                      <li class="tab" :key="type.id" v-if="type.active||type.selected">{{type.name}}</li>
                    </template>
                  </ul>
                </div>
                <div class="module" v-html="item.moduleName"></div>
              </div>
              <div class="middle">
                <div v-if="!item.dateStart" @click="timeSet(item.id)">设置时间</div>
                <div class="flex" v-else>
                  <el-date-picker
                    v-model="item.dateStart"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"
                    @change="itemDateStartChange"></el-date-picker>
                  <div class="text">至</div>
                  <el-date-picker
                    v-model="item.dateEnd"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"
                    @change="itemDateEndChange"></el-date-picker>
                </div>
              </div>
              <div class="right">
                <div class="del-btn" @click="deleteWork(item.id)"></div>
                <div class="time">预计耗时：{{item.time}}分钟</div>
              </div>
            </div>
          </div>
          <div class="statistics-view">
            <div class="statistics-time">共计：{{Object.keys(list).length}}个作业，预计总耗时：{{time}}分钟</div>
            <div class="unify-settings">
              <div v-if="!startTime" @click="totalTimeSet">统一设置本次作业时间</div>
              <div class="time" v-else>
                <el-date-picker
                  v-model="startTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm"
                  @change="dateChange"
                  :clearable="false"></el-date-picker>
                <div class="text">至</div>
                <el-date-picker
                  v-model="endTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm"
                  @change="dateChange"
                  :clearable="false"></el-date-picker>
              </div>
            </div>
            <div style="width: 148px;"></div>
          </div>
        </div>
      </section>
      <section class="homework-settings">
        <h2 class="nav-title" style="margin-bottom: 28px;">作业设置</h2>
        <div class="homework-settings-step">
          <div class="homework-settings-step__head">
            <div class="homework-settings-step__icon">1</div>
            <div class="homework-settings-step__line"></div>
          </div>
          <div class="homework-settings-step__main">
            <div class="homework-settings-step__title">编辑作业名称</div>
            <div class="homework-settings-step__content">
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">作业名称：</div>
                <div class="homework-settings-step__content__input">
                  <el-input v-model="name" placeholder="请输入本次作业名称" size="medium" maxlength="30" style="width: 100%;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homework-settings-step">
          <div class="homework-settings-step__head">
            <div class="homework-settings-step__icon">2</div>
            <div class="homework-settings-step__line"></div>
          </div>
          <div class="homework-settings-step__main">
            <div class="homework-settings-step__title">选择学生</div>
            <div class="homework-settings-step__content">
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">布置方式：</div>
                <div class="homework-settings-step__content__radio">
                  <el-radio-group v-model="assignType" size="medium">
                    <el-radio-button label="classes">按班级布置</el-radio-button>
                    <el-radio-button label="group">按小组布置</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <template v-if="assignType==='classes'">
                <div class="homework-settings-step__content__set" v-if="superAdmin==='2'">
                  <div class="homework-settings-step__content__label">选班方式：</div>
                  <div class="homework-settings-step__content__radio">
                    <el-radio-group v-model="classesType" size="medium" @change="assignTypeSwitch">
                      <el-radio-button label="mine"><span class="space"/>我的班级<span class="space"/></el-radio-button>
                      <el-radio-button label="other"><span class="space"/>其他班级<span class="space"/></el-radio-button>
                    </el-radio-group>
                  </div>
                </div>

                <div v-if="classesType==='mine'"
                     class="homework-settings-step__content__set homework-settings-step__content__set-expand">
                  <div class="homework-settings-step__content__label homework-settings-step__content__label-expand">
                    添加班级：
                  </div>
                  <div class="homework-settings-step__content__classes" v-if="classList.length>0">
                    <div :class="['classes-list', item.active ? 'classes-active' : '']"
                         v-for="(item, index) in classList"
                         :key="item.id" @click="classSelect(index)">{{item.name}}
                    </div>
                  </div>
                  <div class="homework-settings-step__content__classes-none" v-else>
                    <div class="icon-classes-none"></div>
                    <div>
                      <div style="margin-bottom: 10px;">
                        您还没有班级哦~请先前往
                        <router-link :to="{name: 'ClassManage'}" replace class="link-to-classes">
                          创建班级
                        </router-link>
                      </div>
                      <div>或致电：400-996-0201，我们的客服人员会为您创建</div>
                    </div>
                  </div>
                </div>
                <div v-else-if="classesType==='other'"
                     class="homework-settings-step__content__set homework-settings-step__content__set-expand">
                  <div class="homework-settings-step__content__label homework-settings-step__content__label-expand">
                    添加学校：
                  </div>
                  <div class="homework-settings-step__content__school">
                    <div class="school-list" v-for="(item, i) in selectedSchoolList" :key="i">
                      <div class="school-list-left">
                        <img src="../../assets/icon-school.png" alt="school">
                        <div>{{item.name}}</div>
                        <div class="school-list-choose">已选：{{item.classList.length}}个班</div>
                      </div>
                      <div class="school-list-right">
                        <div class="operate-button school-list-modify"
                             @click="schoolDialogVisibleChange(item)">
                          <img src="../../assets/input.png" alt="modify">
                          修改
                        </div>
                        <div class="operate-button school-list-remove"
                             @click="schoolDel(i)">
                          <img src="../../assets/delete.png" alt="delete">
                          移除
                        </div>
                      </div>
                    </div>
                    <div class="homework-settings-step__content__school-add"
                         @click="schoolDialogVisibleChange()">
                      <img src="../../assets/add-4.png" alt="add">
                      <div>添加学校</div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else-if="assignType==='group'"
                   class="homework-settings-step__content__set homework-settings-step__content__set-expand">
                <div class="homework-settings-step__content__label homework-settings-step__content__label-expand">
                  添加小组：
                </div>
                <div class="homework-settings-step__content__group" v-if="classList.length>0">
                  <div class="homework-settings-step__content__group-row" v-for="(item, index) in classList" :key="item.id">
                    <div class="homework-settings-step__content__group-column__left">
                      {{item.name}}
                    </div>
                    <div class="homework-settings-step__content__group-column__right">
                      <template v-if="item.userList && item.userList.length>0">
                        <div class="user-group-list">从全班学生中选择：</div>
                        <el-row :gutter="10">
                          <template v-for="(student, studentIndex) in item.userList">
                            <el-col :key="student.id" :span="4">
                              <div
                                :class="['student-list', student.active ? 'classes-active' : '']"
                                @click="studentSelect(index, studentIndex)"
                              >
                                {{student.realName}}
                              </div>
                            </el-col>
                          </template>
                        </el-row>
                      </template>
                      <template v-if="item.userGroupList.length>0">
                        <div class="user-group-list mt-18">选择已有的分组：</div>
                        <el-row :gutter="10">
                          <template v-for="(group, groupIndex) in item.userGroupList">
                            <el-col :key="groupIndex" :span="8">
                              <el-card class="box-card" shadow="never">
                                <div slot="header" class="clearfix">
                                  <span class="group-name">{{ group.name }}</span>
                                  <el-checkbox v-model="group.active" @change="groupSelect(index, groupIndex)"></el-checkbox>
                                </div>
                                <div class="card-content">
                                  <span style="color: #333333">共<span style="color: #309AF2">{{ group.studentCount }}</span>人</span>
                                  <el-button class="card-content-btn" type="text" @click="viewRoster(group, group.id)">查看名单<i class="el-icon-arrow-right"></i></el-button>
                                </div>
                              </el-card>
                            </el-col>
                          </template>
                        </el-row>
                      </template>
                      <!--<div class="homework-settings-step__content__group-none" v-else>-->
                      <!--  当前班级暂未进行分组~-->
                      <!--</div>-->
                    </div>
                  </div>
                </div>
                <div class="homework-settings-step__content__classes-none" v-else>
                  <div class="icon-classes-none"></div>
                  <div>
                    <div style="margin-bottom: 10px;">
                      您还没有班级哦~请先前往
                      <router-link :to="{name: 'ClassManage'}" replace class="link-to-classes">创建班级
                      </router-link>
                    </div>
                    <div>或致电：400-996-0201，我们的客服人员会为您创建</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homework-settings-step">
          <div class="homework-settings-step__head">
            <div class="homework-settings-step__icon">3</div>
            <div class="homework-settings-step__line"></div>
          </div>
          <div class="homework-settings-step__main">
            <div class="homework-settings-step__title">设置作业条件</div>
            <div class="homework-settings-step__content">
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">得分要求：</div>
                <div class="homework-settings-step__content__select">
                  <el-select v-model="minFinishRateState" size="medium" style="width: 270px;"
                             @change="minFinishRateChange">
                    <el-option :value="0" label="不限制"></el-option>
                    <el-option :value="1" label="设置得分率"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">作答次数：</div>
                <div class="homework-settings-step__content__select">
                  <el-select v-model="answerTimes" size="medium" style="width: 270px;">
                    <el-option :value="1" label="允许重复作答"></el-option>
                    <el-option :value="2" label="只能做一次"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">过期补交：</div>
                <div class="homework-settings-step__content__select">
                  <el-select v-model="expireRedo" size="medium" style="width: 270px;">
                    <el-option :value="2" label="不允许过期补交"></el-option>
                    <el-option :value="1" label="允许过期补交"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">答案公布：</div>
                <div class="homework-settings-step__content__select">
                  <el-select v-model="toPublic" size="medium" style="width: 270px;"
                             @change="toPublicChange">
                    <el-option :value="1" label="完成后立即公布"></el-option>
                    <el-option :value="2" label="自定义公布时间"></el-option>
                    <el-option :value="3" label="作业截止后公布"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homework-settings-step">
          <div class="homework-settings-step__head">
            <div class="homework-settings-step__icon">4</div>
            <div class="homework-settings-step__line"></div>
          </div>
          <div class="homework-settings-step__main">
            <div class="homework-settings-step__title">作业留言</div>
            <div class="homework-settings-step__content">
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">留给家长：</div>
                <div class="homework-settings-step__content__input">
                  <el-input v-model="teacherNotifyMsg" placeholder="对于此作业，您可给家长留言，字数在50字以内。（选填）"
                            size="medium" maxlength="50" show-word-limit style="width: 100%;"/>
                </div>
              </div>
              <div class="homework-settings-step__content__set">
                <div class="homework-settings-step__content__label">留给学生：</div>
                <div class="homework-settings-step__content__input">
                  <el-input v-model="teacherNotifyMsgOfStudent" placeholder="对于此作业，您可给学生留言，字数在50字以内。（选填）"
                            size="medium" maxlength="50" show-word-limit style="width: 100%;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </article>
    <footer class="footer">
      <div class="submit-btn" @click="submit">确认布置</div>
    </footer>

    <setDialog
        :visible="setDialogVisible"
        :setType="setType"
        @confirm="confirmParam"
        @handleClose="setDialogVisibleChange"
    ></setDialog>

    <SchoolDialog
        ref="SchoolDialog"
        :schoolList="schoolList"
        @schoolSave="schoolSave"
    ></SchoolDialog>

    <pointsModal
        ref="pointsModal"
        @confirmCallback="confirmCallback"
    ></pointsModal>
    <GroupStudent ref="GroupStudent" :title="userGroupTitle" :user-group-id="userGroupId"></GroupStudent>
  </div>
</template>

<script>
  import Header from '@/layout/components/header.vue'
  import setDialog from './components/setDialog.vue'
  import SchoolDialog from './components/SchoolDialog.vue'
  import pointsModal from '@/components/PointsModal.vue'
  import GroupStudent from './components/GroupStudent.vue'
  import _ from 'lodash'

  export default {
    name: 'workSubmit',
    components: {
      Header,
      setDialog,
      SchoolDialog,
      pointsModal,
      GroupStudent
    },
    data() {
      return {
        loading: false,
        name: '',
        teacherNotifyMsg: '', // 教师留言
        teacherNotifyMsgOfStudent: '', // 学生留言
        list: [],
        classList: [],
        startTime: null,
        endTime: null,
        assignType: 'classes', // classes: 按班级布置, group: 按小组布置
        classesType: 'mine', // mine: 我的班级  other: 其他班级
        myClass: true,
        superAdmin: '0',
        searchName: '',
        searchId: '',
        searchGradeList: [],
        searchGradeId: '',
        searchYearList: [],
        searchYear: '',
        searchPhone: '',
        schoolList: [],
        selectedSchoolList: {},
        allSelected: false,
        minFinishRateState: 0, // 得分率,0：不限制，1：限制
        minFinishRate: '', // 得分率
        answerTimes: 1, // 1允许重复作答，2只能做一次
        expireRedo: 1, // 1允许过期补交，2不允许过期补交
        toPublic: 1, // 1完成后立即公布，2自定义公布(publicTime不为空，要上传时间)，3作业截止后公布
        publicTime: '',
        setDialogVisible: false,
        setType: 'minFinishRate',
        checked: false,
        userGroupId: '',
        userGroupTitle: ''
      }
    },
    computed: {
      time: function () {
        const {selectedWorkList} = this.$store.state
        let time = 0
        Object.keys(selectedWorkList).forEach((item) => {
          time += selectedWorkList[item].time
        })
        return Number(time).toFixed(1)
      }
    },
    watch: {
      minFinishRate(val) {
        if (val) this.minFinishRateState = `自定义得分率：${val}%`
      },
      publicTime(val) {
        if (val) this.toPublic = `自定义时间：${val}`
      }
    },
    created() {
      this.superAdmin = sessionStorage.getItem('superAdmin')
      if (this.superAdmin === '2') {
        this.classesType = 'mine'
      }
      this.list = this.$store.state.selectedWorkList
      const date = new Date()
      this.name = `${this.common.formatDate('yyyyMMdd', date)}作业任务`
      this.getClass()
    },
    mounted() {
      // window.addEventListener('beforeunload', this.beforeUnloadListener, {capture: true});
    },
    beforeDestroy() {
      // window.removeEventListener('beforeunload', this.beforeUnloadListener, {capture: true});
    },
    methods: {
      beforeUnloadListener(event) {
        return event.returnValue = 'Are you sure you want to exit?'
      },
      schoolDel(id) {
        this.$set(this.selectedSchoolList, id, null)
        delete this.selectedSchoolList[id]
      },
      schoolSave(item) {
        this.$set(this.selectedSchoolList, item.id, item)
      },
      schoolDialogVisibleChange(item) {
        this.$refs.SchoolDialog.visibleChange(item)
      },
      confirmParam(attribute, value) {
        this[attribute] = value
        this.setDialogVisible = false
      },
      minFinishRateChange() {
        if (this.minFinishRateState === 1) {
          this.setType = 'minFinishRate'
          this.minFinishRate = ''
          this.setDialogVisibleChange()
        } else {
          this.minFinishRate = ''
        }
      },
      toPublicChange() {
        if (this.toPublic === 2) {
          this.setType = 'publicTime'
          this.publicTime = ''
          this.setDialogVisibleChange()
        } else {
          this.publicTime = ''
        }
      },
      setDialogVisibleChange(attribute) {
        this.setDialogVisible = !this.setDialogVisible
        if (attribute === 'minFinishRate') {
          this.minFinishRateState = 0
        } else if (attribute === 'publicTime') {
          this.toPublic = 1
        }
      },
      getSchoolList() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.getSchoolList
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            for (let i = 0, len = data.length; i < len; i += 1) {
              data[i].value = data[i].schoolName
            }
            this.schoolList = data
          }
        }).catch(() => {
          this.loading = false
        })
      },
      // 选择学生
      studentSelect(classesIndex, groupIndex) {
        const {active} = this.classList[classesIndex].userList[groupIndex]
        this.$set(this.classList[classesIndex].userList[groupIndex], 'active', !active)
      },
      // 选择分组
      groupSelect(classesIndex, groupIndex) {
        const {active} = this.classList[classesIndex].userGroupList[groupIndex]
        this.$set(this.classList[classesIndex].userGroupList[groupIndex], 'active', active)
      },
      // 查看名单
      viewRoster(group, id) {
        this.userGroupId = id
        this.userGroupTitle = group.name
        this.$nextTick(() => {
          this.$refs.GroupStudent.changeVisible(true)
        })
      },
      // 获取班级
      getClass() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.gradeClassList,
          data: {
            category: 3,
            queryStudent: 1
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.classList = response.data
          }
        }).catch(() => {
          this.loading = false
        })
      },
      classSelect(index) {
        const {active} = this.classList[index]
        this.$set(this.classList[index], 'active', !active)
      },
      totalTimeSet() {
        const that = this
        const {list} = this
        let inList = false
        for (let i = 0, len = list.length; i < len; i += 1) {
          if (list[i].dateStart) {
            inList = true
            break
          }
        }
        if (inList) {
          this.$confirm('统一设置本次作业时间后，单个作业的时间将被清除', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            for (let i = 0, len = list.length; i < len; i += 1) {
              if (list[i].dateStart) {
                list[i].dateStart = null
                list[i].dateEnd = null
              }
            }
            that.setTime()
          })
          return
        }
        this.setTime()
      },
      dateChange() {
        const {list} = this
        Object.keys(list).forEach((i) => {
          if (list[i].dateStart) {
            list[i].dateStart = null
            list[i].dateEnd = null
          }
        })
      },
      setTime() {
        const date = new Date()
        this.startTime = this.common.formatDate('yyyy-MM-dd hh:mm', date)
        const nextWeek = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000 - (date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds()) * 1000 + 8 * 60 * 60 * 1000)
        this.endTime = this.common.formatDate('yyyy-MM-dd hh:mm', nextWeek)
      },
      timeSet(id) {
        const date = new Date()
        const todayFormat = this.common.formatDate('yyyy-MM-dd hh:mm', date)
        const nextWeek = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000 - (date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds()) * 1000 + 8 * 60 * 60 * 1000)
        const nextWeekFormat = this.common.formatDate('yyyy-MM-dd hh:mm', nextWeek)
        const dateStart = this.startTime || todayFormat
        const dateEnd = this.endTime || nextWeekFormat
        this.$set(this.list[id], 'dateStart', dateStart)
        this.$set(this.list[id], 'dateEnd', dateEnd)
      },
      itemDateStartChange(val) {
        if (!this.startTime) return
        const valTime = new Date(val).getTime()
        const startTime = new Date(this.startTime).getTime()
        if (valTime < startTime) {
          this.startTime = val
        }
      },
      itemDateEndChange(val) {
        if (!this.endTime) return
        const valTime = new Date(val).getTime()
        const endTime = new Date(this.endTime).getTime()
        if (valTime > endTime) {
          this.endTime = val
        }
      },
      deleteWork(id) {
        const obj = {
          id
        }
        this.$store.commit('listDel', obj)
        const {list} = this
        delete list[obj.id]
        if (Object.keys(this.$store.state.selectedWorkList).length === 0) this.$router.back()
      },
      assignTypeSwitch() {
        if (this.classesType === 'other' && this.schoolList.length === 0) {
          this.getSchoolList()
        }
      },
      submit: _.debounce(function () {
        let startTime = this.common.binarySystem(this.startTime)
        let endTime = this.common.binarySystem(this.endTime)
        const now = new Date().getTime()
        // 判断截止时间合法
        if (startTime) {
          const start = new Date(startTime).getTime()
          const end = new Date(endTime).getTime()
          if (end < now) {
            this.$alert('截止时间应大于当前时间', '提示', {
              confirmButtonText: '确定'
            })
            return
          }
          if (end <= start) {
            this.$alert('截止时间应大于开始时间', '提示', {
              confirmButtonText: '确定'
            })
            return
          }
        }
        const {assignType, classesType} = this
        const {classList} = this
        const clazzIdArr = []
        // let groupArr = []
        let studyGroups = []
        let virtualGroups = []
        let category = 1
        let teacherId = this.$store.state.userInfo.id || ''
        // 按班级布置--我的班级
        if (assignType === 'classes' && classesType === 'mine') {
          for (let i = 0, len = classList.length; i < len; i += 1) {
            if (classList[i].active) clazzIdArr.push(classList[i].id)
          }
          // 按班级布置--其他班级
        } else if (assignType === 'classes' && classesType === 'other') {
          const {selectedSchoolList} = this
          Object.values(selectedSchoolList).forEach(item => {
            const {classList} = item
            for (let i = 0, len = classList.length; i < len; i += 1) {
              clazzIdArr.push(classList[i].classId)
            }
          })
          // 按小组布置
        } else if (assignType === 'group') {
          category = 3 // 小组作业
          for (let i = 0, len = classList.length; i < len; i += 1) {
            const {userGroupList: userGroupList = [], userList: userList = []} = classList[i]
            const groups = userGroupList.filter(el => el.active).map(el => el.id)
            if (groups.length > 0) {
              clazzIdArr.push(classList[i].id)
              studyGroups.push({
                clazzId: classList[i].id,
                groupIds: groups
              })
              // groupArr = [...groupArr, ...groups]
            }
            const studentIds = userList.filter(el => el.active).map(el => el.id)
            if (studentIds.length) {
              if (!clazzIdArr.includes(classList[i].id)) clazzIdArr.push(classList[i].id)
              // 虚拟小组列表数据
              virtualGroups.push({
                clazzId: classList[i].id,
                studentIds: userList.filter(el => el.active).map(el => el.id)
              })
            }
          }
        }
        if (clazzIdArr.length === 0) {
          this.$alert('请选择班级', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        const clazzId = clazzIdArr.join()
        // let userGroupIds
        // if (groupArr.length > 0) {
        //   userGroupIds = groupArr.join()
        // }

        const name = this.name.trim()
        if (name === '') {
          this.$alert('请输入作业名称', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        // 设置作业数据
        const {list} = this
        const itemList = []
        let minDateStart
        let maxDateEnd
        let tips = ''
        Object.keys(list).forEach((i, index) => {
          const {flag} = list[i]
          const obj = {}
          obj.name = list[i].name
          obj.flag = list[i].flag
          obj.seq = index + 1
          obj.totalScore = 100
          obj.type = list[i].moduleName
          obj.moduleId = list[i].moduleId
          const dateStart = this.common.binarySystem(list[i].dateStart)
          const dateEnd = this.common.binarySystem(list[i].dateEnd)
          if (!dateStart && !startTime) {
            tips = '请设置时间'
            return false
          }
          const dateStartTime = new Date(dateStart).getTime()
          const dateEndTime = new Date(dateEnd).getTime()
          if (dateStartTime && dateStartTime >= dateEndTime) {
            tips = '截止时间应大于开始时间'
            return false
          }
          if (dateStart) {
            if (!minDateStart) {
              minDateStart = dateStart
              maxDateEnd = dateEnd
            } else {
              const minDateStartTime = new Date(minDateStart).getTime()
              const maxDateEndTime = new Date(maxDateEnd).getTime()
              if (minDateStartTime > dateStartTime) {
                minDateStart = dateStart
              }
              if (maxDateEndTime < dateEndTime) {
                maxDateEnd = dateEnd
              }
            }
            obj.dateStart = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateStart))
            obj.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd))
          } else {
            obj.dateStart = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(startTime))
            obj.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(endTime))
          }
          const {typeList} = list[i]
          const typeArr = []
          if (list[i].type === 'written') {
            const {writtenObj} = list[i]
            const writtenArr = []
            Object.keys(writtenObj).forEach(item => {
              const {seq} = writtenObj[item]
              delete writtenObj[item].seq
              const arr = Object.values(writtenObj[item])
              arr.sort(this.common.compare('seq'))
              const obj = {
                writeLibType: item,
                seq,
                arr
              }
              writtenArr.push(obj)
            })
            writtenArr.sort(this.common.compare('seq'))
            const paperQuestionIds = []
            obj.totalScore = 0
            for (let j = 0, len = writtenArr.length; j < len; j += 1) {
              const {arr} = writtenArr[j]
              if (arr && arr.length > 0) {
                const wObj = {}
                wObj.id = this.common.getUUID(32)
                wObj.score = 0
                wObj.writeLibType = writtenArr[j].writeLibType
                wObj.type = arr[0].type
                wObj.libList = []
                for (let k = 0, len = arr.length; k < len; k += 1) {
                  wObj.score += arr[k].totalScore
                  wObj.libList.push(arr[k].id)
                }
                paperQuestionIds.push(wObj)
                obj.totalScore += wObj.score
              }
            }
            obj.paperQuestionIds = JSON.stringify(paperQuestionIds)
            obj.flag = 4
            obj.moduleId = list[i].moduleId
            itemList.push(obj)
          } else {
            switch (Number(flag)) {
              case 7:
                for (let j = 0, len = typeList.length; j < len; j += 1) {
                  if (typeList[j].id) {
                    typeArr.push(typeList[j].id)
                  }
                }
                obj.typeList = typeArr.join()
                const {wordList} = list[i]
                const ids = []
                for (let j = 0, len = wordList.length; j < len; j += 1) {
                  ids.push(wordList[j].id)
                }
                obj.paperQuestionIds = ids.join()
                itemList.push(obj)
                break
              case 2:
                obj.paperQuestionIds = list[i].id
                itemList.push(obj)
                break
              case 3:
              case 4:
                obj.flag = 3
                obj.paperGroupId = list[i].id
                obj.paperQuestionIds = list[i].id
                for (let j = 0, len = typeList.length; j < len; j += 1) {
                  if (typeList[j].id) {
                    typeArr.push(typeList[j].id)
                  }
                }
                obj.typeList = typeArr.join()
                itemList.push(obj)
                break
              case 5:
                obj.paperGroupId = list[i].id
                obj.paperQuestionIds = list[i]?.Ids?.map(item => `'${item}'`).join(',')
                itemList.push(obj)
                break
              case 8:
                obj.paperGroupId = list[i].id
                obj.paperQuestionIds = list[i].id
                obj.moduleId = list[i].moduleId
                obj.typeList = 3
                itemList.push(obj)
                break
              case 9:
                obj.flag = 3
                obj.paperGroupId = list[i].id
                obj.paperQuestionIds = list[i].id
                itemList.push(obj)
                break
              case 10:
                obj.flag = 9
                const questionIds = []
                list[i].wordList.forEach( word => {
                  if (word.id) {
                    questionIds.push({
                      id: word.id,
                      materialType: word.materialType,
                      groupId: list[i].wordGroupId
                    })
                  }
                })
                obj.paperQuestionIds = JSON.stringify(questionIds)
                obj.typeList = list[i].typeList.map(item => item.id).sort().join(',')
                itemList.push(obj)
                break
              case 24:
                obj.paperGroupId = ''
                const paperQuestionIds =  {
                  materialId: list[i].id, // 素材ID
                  materialType: list[i].materialType, // 素材类型，6对话、7篇章
                  materialParams: []
                }
                if (list[i].typeList && list[i].typeList.length > 0) {
                  list[i].typeList.forEach(item => {
                    if(item.id === 1) {
                      paperQuestionIds.materialParams.push({
                        practiceType: 1,
                        paragraphType: 1
                      })
                    }
                    if(item.id === 2) {
                      paperQuestionIds.materialParams.push({
                        practiceType: 2,
                        sentenceIds: list[i].allSentencesId
                      })
                    }
                    if(item.id === 5) {
                      paperQuestionIds.materialParams.push({
                        practiceType: 5,
                        sentenceIds: list[i].allSentencesId
                      })
                    }
                  })
                }
                obj.paperQuestionIds = JSON.stringify(paperQuestionIds)
                obj.typeList = list[i].typeList.map(item => item.id).sort().join(',')
                itemList.push(obj)
                break
              case 100:
                obj.flag = 4
                obj.paperGroupId = list[i].id
                obj.paperQuestionIds = list[i].id
                itemList.push(obj)
                break
            }
          }
        })
        if (tips) {
          this.$alert(tips, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        startTime = !startTime ? minDateStart : startTime
        const startTimeTime = new Date(startTime).getTime()
        const status = startTimeTime > now ? 0 : 1
        startTime = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(startTime))
        endTime = !endTime ? maxDateEnd : endTime
        endTime = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(endTime))
        if (itemList.length === 0) return
        const {minFinishRate} = this
        const {answerTimes} = this
        const {expireRedo} = this
        let {toPublic} = this
        let {publicTime} = this
        if (publicTime) {
          publicTime = publicTime.replace(/-/g, '/')
          publicTime = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(publicTime))
          toPublic = 2
        }
        this.loading = true
        this.$axios({
          method: 'post',
          // url: this.$urls.homeworkSave,
          url: this.$urls.newHomeworkSave,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            clazzId,
            teacherId,
            studyGroups,
            dateStart: startTime,
            dateEnd: endTime,
            itemList,
            status,
            name,
            category,
            minFinishRate,
            answerTimes,
            expireRedo,
            toPublic,
            teacherNotifyMsg: this.teacherNotifyMsg,
            teacherNotifyMsgOfStudent: this.teacherNotifyMsgOfStudent,
            publicTime,
            virtualGroups,
            taskType: 1
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.$store.commit('listClear')
            this.$store.commit('includeDel', 'workSubmit')

            // 积分弹窗
            const {data} = response
            if (data && data.rewardPointsAddSuccess) {
              this.$refs.pointsModal.visibleChange({
                rewardPoints: data.rewardPoints,
                rewardPointsMsg: data.rewardPointsMsg
              })
            } else {
              this.$message({
                message: '布置作业成功！',
                type: 'success'
              })
              this.$router.push({
                name: 'checkWork'
              })
            }
          }
        }).catch(() => {
          this.loading = false
        })
      }, 1000, {leading: true, trailing: false}),
      confirmCallback() {
        this.$router.push({
          name: 'checkWork'
        })
      }
    }
  }
</script>

<style lang="scss">
  .work-submit-container {
    .el-date-editor--datetime {
      width: 120px;
      font-size: 15px;

      .el-input__inner {
        border: none;
        padding: 0;
        text-decoration: underline;
      }

      .el-input__prefix {
        display: none;
      }
    }
  }

  .homework-settings-step__content__input {
    width: 100%;

    .el-input__inner {
      height: 44px;
      line-height: 44px;
      color: #333333;
      font-size: 17px;
    }
  }

  .homework-settings-step__content__radio {
    .el-radio-button__inner {
      font-size: 15px;
      font-weight: normal;
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #309AF2;
      font-weight: bold;
      background-color: #EAF4FD;
      border-color: #DCDFE6;
      -webkit-box-shadow: -1px 0 0 0 #DCDFE6;
      box-shadow: -1px 0 0 0 #DCDFE6;
    }
  }

  .homework-settings-step__content__select {
    .el-input__inner {
      height: 40px;
      line-height: 40px;
      font-size: 15px;
    }
  }
</style>
<style lang="scss" scoped>
  .work-submit{
    height: 100vh;
    padding-top: 80px;
  }
  .work-submit-container {
    max-width: 1100px;
    background-color: #ffffff;
    padding: 28px;
    margin: 0 auto;
    position: relative;
    z-index: 99;

    .homework-content {
      &-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
      }

      .continue-btn {
        @include flex(flex-end);
        width: 174px;
        @include font(17px, #309AF2);
        cursor: pointer;

        .icon {
          @include icon(url("../../assets/arrow-blue.png"), 6px, 11px);
          margin-left: 6px;
          margin-right: 28px;
        }
      }

      &-list-view {
        width: 1044px;
        min-height: 132px;
        border-radius: 8px;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;

        .list-item {
          height: 88px;
          padding: 0 28px;
          border-bottom: 1px solid #F6F6F6;

          .list-content {
            @include flex(space-between);
            height: 88px;
            @include font(17px);

            .left {
              flex-shrink: 0;
              width: 502px;

              .flex {
                @include flex(flex-start);
                margin-bottom: 10px;

                .name {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .tabs {
                  @include flex;
                  margin-left: 20px;

                  .tab {
                    background-color: #EAF4FD;
                    height: 26px;
                    line-height: 26px;
                    padding: 0 10px;
                    border-radius: 4px;
                    margin-right: 10px;
                    @include font(14px, #309AF2, center);
                    white-space: nowrap;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }

              .module {
                @include font(15px, #999);
              }
            }

            .middle {
              color: #333333;
              font-size: 15px;
              text-decoration: underline;
              cursor: pointer;

              .flex {
                @include flex;
              }

              .text {
                margin: 0 10px;
              }
            }

            .right {
              @include flex(flex-start, flex-end);
              flex-direction: column;
              width: 148px;

              .del-btn {
                background: url("../../assets/delete.png") no-repeat center;
                background-size: 16px 16px;
                width: 60px;
                height: 34px;
                border: 1px solid #E5E5E5;
                border-radius: 17px;
                margin-bottom: 10px;
                cursor: pointer;

                &:hover {
                  background-color: #E5E5E5;
                }
              }

              .time {
                @include font(15px, #999);
              }
            }
          }

          &:hover {
            background-color: #F6F6F6;
          }

          &:first-child {
            border-radius: 8px 8px 0 0;
          }
        }

        .statistics-view {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 44px;
          background-color: #F6F6F6;
          padding: 0 28px;
          border-radius: 0 0 8px 8px;

          .statistics-time {
            width: 502px;
            color: #309AF2;
            font-size: 15px;
          }

          .unify-settings {
            @include font(15px, #FF3C30);
            text-decoration: underline;
            cursor: pointer;

            .time {
              @include flex;
              font-size: 15px;
              color: #333333;

              .text {
                margin: 0 10px;
              }
            }
          }
        }
      }
    }

    .homework-settings {
      margin-top: 28px;

      &-step {
        display: flex;
        justify-content: flex-start;
        min-height: 98px;

        &__head {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        &__line {
          width: 1px;
          height: 100%;
          background-color: #C8C8C8;
        }

        &__icon {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 20px;
          color: #ffffff;
          border-radius: 50%;
          background-color: #309AF2;
        }

        &__main {
          width: 984px;
          padding-bottom: 28px;
          margin-left: 20px;
        }

        &__title {
          line-height: 40px;
          color: #333333;
          font-size: 17px;
          margin-bottom: 14px;
        }

        &__content {
          &__set {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__set-expand {
            align-items: flex-start;
            height: auto;
          }

          &__label {
            flex-shrink: 0;
            width: 90px;
            font-size: 15px;
            color: #333333;
          }

          &__label-expand {
            margin-top: 8px;
          }

          &__classes {
            @include flex(flex-start);
            flex-wrap: wrap;
            margin-bottom: -14px;
          }

          &__classes-none {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 800px;
            height: 148px;
            color: #333333;
            font-size: 17px;
            background: #F6F6F6;
            border-radius: 8px;
            border: 1px solid #C8C8C8;
            padding: 0 56px;

            .icon-classes-none {
              width: 120px;
              height: 107px;
              background: url("../../assets/icon-classes-none.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 20px;
            }
          }

          .classes-list, .student-list {
            height: 36px;
            line-height: 36px;
            border-radius: 4px;
            color: #333333;
            text-align: center;
            cursor: pointer;
            border: 1px solid #C8C8C8;
            background-color: #F6F6F6;
            padding: 0 10px;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;

            &:hover {
              color: #333333;
            }
          }
          .classes-list{
            margin-right: 14px;
          }

          .classes-active {
            background-color: #EAF4FD;
            color: #309AF2;
            border-color: #309AF2;

            &:hover {
              color: #309AF2;
            }
          }

          &__school {

            &-add {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 110px;
              height: 36px;
              color: #309AF2;
              font-size: 15px;
              cursor: pointer;
              border-radius: 4px;
              border: 1px solid #309AF2;
              background-color: #EAF4FD;

              img {
                width: 16px;
                height: 16px;
                margin-right: 6px;
              }
            }

          }

          .school-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 895px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #E5E5E5;
            padding: 0 28px 0 20px;
            margin-bottom: 14px;

            &:hover {
              background-color: #F6F6F6;
            }

            &-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: #333333;
              font-size: 15px;

              img {
                width: 20px;
                height: 20px;
                margin-right: 14px;
              }
            }

            &-choose {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 28px;
              color: #309AF2;
              font-size: 12px;
              border-radius: 4px;
              background-color: #EAF4FD;
              padding: 0 10px;
              margin-left: 16px;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 10px;
                left: -12px;
                width: 0;
                border-width: 5px 6px;
                border-style: solid;
                border-right-color: #C0E0FB;
                border-left-color: transparent;
                border-top-color: transparent;
                border-bottom-color: transparent;
              }
            }

            &-right {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .operate-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 72px;
                height: 30px;
                font-size: 13px;
                cursor: pointer;
                background: #FFFFFF;
                border-radius: 15px;

                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 4px;
                }

                &:hover {
                  background-color: #E5E5E5;
                }
              }

            }

            &-modify {
              color: #333333;
              border: 1px solid #C8C8C8;
              margin-right: 14px;
            }

            &-remove {
              color: #FF3C30;
              border: 1px solid #FF3C30;
            }
          }

          &__group {
            width: 895px;
            min-height: 64px;
            border-radius: 8px;
            border: 1px solid #E5E5E5;
            background-color: #F6F6F6;

            &-row {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              min-height: 64px;
              border-bottom: 1px solid #E5E5E5;

              &:first-child {
                .homework-settings-step__content__group-column__right {
                  border-radius: 0 8px 0 0;
                }
              }

              &:last-child {
                border-bottom-width: 0;

                .homework-settings-step__content__group-column__right {
                  border-radius: 0 0 8px 0;
                }
              }
              .user-group-list{
                color: #999999;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 10px;
              }
            }

            &-column__left {
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 140px;
              min-height: 64px;
              color: #333333;
              font-size: 15px;
            }

            &-column__right {
              flex-wrap: wrap;
              width: 100%;
              min-height: 64px;
              padding: 14px 14px 4px;
              background-color: #FFFFFF;
              border-left: 1px solid #E5E5E5;
            }

            &-list {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              margin-bottom: -14px;
            }

            &-none {
              color: #333333;
              font-size: 15px;
            }
          }
        }
      }
    }

    .nav-title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }

    .link-to-classes {
      color: #309AF2;
      cursor: pointer;
      text-decoration: underline;
    }

    .space {
      display: inline-block;
      width: 7.5px;
    }
  }
  .mt-18{
    margin-top: 18px;
  }

  .footer {
    @include flex;
    max-width: 1100px;
    height: 84px;
    border-top: 1px solid #E5E5E5;
    background-color: #ffffff;
    margin: 0 auto;

    .submit-btn {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      width: 250px;
      height: 46px;
      line-height: 46px;
      border-radius: 4px;
      @include font(17px, #fff, center);
      cursor: pointer;

      &:hover {
        background: #309AF2;
      }

      &:active {
        background: #6DBBFC;
      }
    }
  }
  ::v-deep .el-card{
    &.box-card{
      //box-shadow: none;
      border: 1px solid #E5E5E5;
      margin-bottom: 10px;
      .el-card__header{
        padding: 14px 14px 8px;
        .clearfix{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          .group-name{
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
      .el-card__body{
        padding: 10px 14px 14px;
        .card-content{
          font-size: 15px;
          line-height: 21px;
          .card-content-btn{
            float: right;
            padding: 3px 0;
            color: #999999;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
</style>
