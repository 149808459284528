<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="30%"
    custom-class="group-student"
    :before-close="handleClose"
  >
    <div>
      <el-row :gutter="10">
        <template v-for="student in studentsList">
          <el-col :key="student.id" :span="4">
            <div class="student-list">{{ student.realName }}</div>
          </el-col>
        </template>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
  </span>
  </el-dialog>
</template>

<script>
import {getStudentsForGroup} from "@/api/group";

export default {
  name: 'GroupStudent',
  props: {
    userGroupId: {
      type: [Number, String],
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      studentsList: []
    };
  },
  methods: {
    changeVisible(bol) {
      this.dialogVisible = bol
      if (bol) this.loadStudentsData()
    },
    handleClose(done) {
      done()
    },
    async loadStudentsData() {
      try {
        const res = await getStudentsForGroup(this.userGroupId)
        if (res.state === '11') {
          this.studentsList = res.data.map(item => {
            item.selected = false
            return item
          })
        }
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.student-list{
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  background-color: #F6F6F6;
  color: #333333;
  font-size: 15px;
}
::v-deep .el-dialog{
  &.group-student{
    border-radius: 10px;
    .el-dialog__header{
      padding: 25px 28px;
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      .el-dialog__headerbtn{
        width: 50px;
        height: 50px;
        top: 14px;
        right: 14px;
        font-size: 22px;
        border-radius: 25px;
        color: black;
        .el-icon-close{
          color: black;
        }
        &:hover{
          background-color: #F6F6F6;
          .el-icon-close{
            color: black;
          }
        }
      }
    }
    .el-dialog__body{
      padding: 0 28px 18px;
    }
    .el-dialog__footer{
      padding: 0;
    }
  }
}

</style>
