<template>
    <el-dialog
            width="540px"
            :title="title"
            center
            :visible.sync="visible"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
        <div class="minFinishRate" v-if="setType === 'minFinishRate'">
            <div class="wrapper">
                <el-input-number v-model="rate" size="medium" :min="1" :max="100" :precision="0"></el-input-number>
                <div class="icon">%</div>
            </div>
            <div class="tips">
                如果学生作业得分率低于您设置的得分率，学生作业将会自动打回重做。一旦设置最低得分率后将不可修改。请谨慎设置得分率！
            </div>
        </div>
        <div class="text-center" v-else>
            <el-date-picker
                    v-model="publicTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"></el-date-picker>
        </div>
        <div class="footer">
            <el-button class="btn" type="primary" @click="confirm">确定</el-button>
            <el-button class="btn" @click="handleClose(setType)">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'setDialog',
        props: ['visible', 'setType'],
        data() {
            return {
                title: '设置最低得分率',
                rate: 60,
                publicTime: null
            }
        },
        watch: {
            setType(val) {
                if (val === 'minFinishRate') {
                    this.title = '设置最低得分率'
                } else {
                    this.title = '设置公布时间'
                }
            }
        },
        methods: {
            handleClose(setType) {
                this.$emit('handleClose', setType)
            },
            confirm() {
                const {setType} = this
                const {rate} = this
                const {publicTime} = this
                let value
                if (setType === 'publicTime' && publicTime == null) return
                if (setType === 'minFinishRate') {
                    value = rate
                } else {
                    value = publicTime
                }
                this.$emit('confirm', setType, value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .minFinishRate {
        width: 348px;
        margin: 0 auto;

        .wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            /*position: relative;*/

            .icon {
                background-color: #fff;
                width: 37px;
                height: 48px;
                line-height: 50px;
                color: #333;
                margin-left: 20px;
                /*position: absolute;*/
                /*top: 1px;*/
                /*right: 1px;*/
            }
        }
    }

    .tips {
        margin-top: 10px;
        color: #FF3C30;
        font-size: 15px;
        white-space: pre-line;
    }

    .footer {
        text-align: center;

        .btn {
            width: 160px;
            height: 40px;
            margin-top: 28px;
            font-size: 17px;
        }
    }
</style>
