import request from "../utils/https";

/**
 * 获取班级列表
 * @returns {AxiosPromise}
 */
export function getClassesList() {
  return request({
    url: '/gradeClass/list',
    method: 'POST'
  })
}

/**
 * 获取班级详情-拿到学生列表
 * @param {String} id 班级ID
 * @return {AxiosPromise}
 */
export function getStudents(id) {
  return request({
    url: '/gradeClass/detail',
    method: 'POST',
    data: {id}
  })
}

/**
 * 批量创建班级
 * ex：我也不知道为啥用这个接口来创建，没办法，难受
 * @param {String} clazzListInfo '[{"name":"12班","gradeId":"1","year":"2020"}]'
 * @return {AxiosPromise}
 */
export function createClass(clazzListInfo) {
  return request({
    url: '/gradeClass/saveList',
    method: 'POST',
    data: {
      clazzListInfo
    }
  })
}

/**
 * 修改班级
 * @param {String} id
 * @param {String} className
 * @param {Number} allowJoin 是否允许加入 1-允许 0-不允许
 * @param {Number} gradeId 年级
 * @param {Number} enrollmentYear 入学年份
 * @return {AxiosPromise}
 */
export function updateClass(id, className, allowJoin, gradeId, enrollmentYear) {
  return request({
    url: '/apiv2/teacher/clazz/updateById',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      id,
      className,
      allowJoin,
      gradeId,
      enrollmentYear
    }
  })
}

/**
 * 转让班级
 * @param {String} clazzId
 * @param {String} transferTeacherId
 * @param {String} phone
 * @param {String} code
 * @return {AxiosPromise}
 */
export function transformClass(clazzId, transferTeacherId, phone, code) {
  return request({
    url: '/gradeClass/transfer',
    method: 'POST',
    data: {
      clazzId,
      transferTeacherId,
      phone,
      code
    }
  })
}

/**
 * 转让班级时获取验证码
 * @param {String} phone
 * @return {AxiosPromise}
 */
export function getAuthCode(phone) {
  return request({
    url: '/gradeClass/getMsgCode',
    method: 'POST',
    data: {phone}
  })
}

/**
 * 获取学生详情
 * @param {String} id 学生ID
 * @return {AxiosPromise}
 */
export function getStudent(id) {
  return request({
    url: '/user/get',
    method: 'POST',
    data: {id}
  })
}

/**
 * 修改学生姓名
 * @param userId
 * @param realName
 * @return {AxiosPromise}
 */
export function renameStudent(userId, realName) {
  return request({
    url: '/user/updateRealName',
    method: 'POST',
    data: {
      userId,
      realName
    }
  })
}

/**
 * 获取年级列表
 * @return {AxiosPromise}
 */
export function getGradeList() {
  return request({
    url: '/gradeClass/gradeTypeList',
    method: 'POST'
  })
}

/**
 * 获取可选的入学年份列表
 * @param section 学校的学段 1-初中 2-高中 3-完中
 * @param yearSystem 学校的年制 0-三年制 1-四年制
 * @return {AxiosPromise}
 */
export function getEnrollmentYears(data) {
  return request({
    url: '/apiv2/teacher/grade/getEnrollmentYears',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 获取学校下的所有教师
 * @param {String} schoolId
 * @return {AxiosPromise}
 */
export function getTeacherList(schoolId) {
  return request({
    url: '/teacher/findListBySchoolId',
    method: 'POST',
    data: {
      schoolId
    }
  })
}

/**
 * 移除学生 - 个人端
 * @param {String} userIds 学生ID，逗号分隔
 * @return {AxiosPromise}
 */
export function removeStudents(userIds) {
  return request(({
    url: '/user/removeByUserIds',
    method: 'POST',
    data: {userIds}
  }))
}

/**
 * 移除学生 - 考试端
 * @param {Array} examineeIds
 * @return {AxiosPromise}
 */
export function removeStudentsForExam(examineeIds) {
  return request(({
    url: '/apiv2/teacher/examinee/leaveClass',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {examineeIds}
  }))
}

/**
 * 解散班级
 * @param {String} id 班级ID
 * @return {AxiosPromise}
 */
export function dissolveClass(id) {
  return request({
    url: '/gradeClass/remove',
    method: 'POST',
    data: {id}
  })
}

