<template>
  <article>
    <div class="bg" @click="handleClose" v-show="visible"></div>
    <transition name="slide">
      <div class="wrapper" v-show="visible">
        <header class="header">
          <h1>添加学校</h1>
          <div class="icon" @click="handleClose"></div>
        </header>
        <div class="search-list">
          <div>
            <el-autocomplete
                class="search-name"
                v-model="searchName"
                :fetch-suggestions="querySearch"
                placeholder="请输入学校名称搜索"
                :trigger-on-focus="false"
                @select="selectSchool"
            ></el-autocomplete>
            <el-select class="search-grade" v-model="searchGradeId" placeholder="年级">
              <el-option v-for="(item, index) in searchGradeList" :key="index" :label="item.gradeName"
                         :value="item.gradeId"></el-option>
            </el-select>
          </div>
          <!--<div class="text">或</div>-->
          <!--<el-input v-model="searchPhone" placeholder="输入教师手机号码搜索"></el-input>-->
          <div class="search-btn">
            <el-button type="primary" @click="getOtherClasses()">查询</el-button>
          </div>
        </div>
        <div class="container">
          <el-table ref="multipleTable" :data="otherClassList" @selection-change="handleSelectionChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="classNum" label="班级ID" align="center"></el-table-column>
            <el-table-column prop="className" label="班级名称" align="center"></el-table-column>
            <el-table-column prop="gradeName" label="年级" align="center"></el-table-column>
            <el-table-column prop="name" label="教师" align="center"></el-table-column>
            <el-table-column prop="phone" label="教师电话" align="center"></el-table-column>
            <el-table-column prop="studentCount" label="学生数" align="center"></el-table-column>
          </el-table>
        </div>
        <div class="foot">
          <el-button class="btn" type="primary" @click="schoolSave">确认</el-button>
        </div>
      </div>
    </transition>
  </article>
</template>

<script>
import {getEnrollmentYears} from "@/api/classes";

export default {
  name: 'SchoolDialog',
  props: ['schoolList'],
  data() {
    return {
      visible: false,
      loading: false,
      searchName: '',
      searchId: '',
      searchGradeList: [],
      searchGradeId: '',
      // searchYearList: [],
      searchPhone: '',
      otherClassList: [],
      selectedClassList: [],
      schoolSection: null,
      yearSystem: null
    }
  },
  watch: {
    searchName(val) {
      if (!val) return
      const {schoolList} = this
      for (let i = 0, len = schoolList.length; i < len; i += 1) {
        if (val === schoolList[i].schoolName) {
          this.searchId = schoolList[i].schoolId
          break
        }
      }
    },
    searchId(val) {
      this.searchGradeId = ''
      // this.getGradeBySchoolId(val)
      this.getEnrollmentYears(val)
    }
  },
  methods: {
    visibleChange(item) {
      // if (item) {
      //   this.searchName = item.name
      //   this.searchGradeId = item.searchGradeId
      //   this.getGradeBySchoolId(item.id, () => {
      //     this.getOtherClasses(item.classList)
      //   })
      //   // this.getEnrollmentYears(item.id, () => {
      //   //   this.getOtherClasses(item.classList)
      //   // })
      // } else {
      //   this.searchName = ''
      //   this.searchGradeId = ''
      //   this.searchId = ''
      //   this.otherClassList = []
      // }
      this.searchName = ''
      this.searchGradeId = ''
      this.searchId = ''
      this.otherClassList = []
      this.visible = true
    },
    // 获取入学年份/年级
    async getEnrollmentYears(callback) {
      try {
        this.loading = true
        const data = {
          section: this.schoolSection,
          yearSystem: this.yearSystem
        }
        const res = await getEnrollmentYears(data)
        this.loading = false
        if (res.state === '11') {
          res.data.sort((a, b) => {
            return a.gradeId - b.gradeId
          })
          this.searchGradeList = res.data
          if (typeof callback === 'function') callback()
        }
      } catch (e) {
        this.loading = false
      }
    },
    // getGradeBySchoolId(val, callback) {
    //   this.loading = true
    //   if (!val) return
    //   this.$axios({
    //     method: 'post',
    //     url: this.$urls.getGradeBySchoolId,
    //     data: {
    //       schoolId: val
    //     }
    //   }).then((response) => {
    //     this.loading = false
    //     if (response.state === '11') {
    //       const {data} = response
    //       let searchGradeList = []
    //       for (let i = 0, len = data.length; i < len; i += 1) {
    //         if (data[i].gradeTypeId) {
    //           searchGradeList.push(data[i])
    //         }
    //       }
    //       searchGradeList = this.common.uniqueForObj(searchGradeList, 'gradeName')
    //       searchGradeList.sort((a, b) => {
    //         return a.gradeTypeId - b.gradeTypeId
    //       })
    //       this.searchGradeList = searchGradeList
    //       if (typeof callback === 'function') callback()
    //     }
    //   }).catch(() => {
    //     this.loading = false
    //   })
    // },
    // 选择学校
    selectSchool(val) {
      this.schoolSection = val.section
      this.yearSystem = val.yearSystem
    },
    getOtherClasses(classList) {
      const searchPhone = this.searchPhone.trim()
      const dataParam = {}
      if (searchPhone !== '') {
        dataParam.phone = searchPhone
      } else {
        const {searchId} = this
        if (searchId === '') {
          this.$alert('请选择学校', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        dataParam.schoolId = searchId
        if (this.searchGradeId !== '') dataParam.gradeList = this.searchGradeId
      }
      this.$axios({
        method: 'post',
        url: this.$urls.getOtherClasses,
        data: dataParam
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          this.otherClassList = data
          if (classList) {
            for (let i = 0, len = classList.length; i < len; i += 1) {
              for (let j = 0, len = data.length; j < len; j += 1) {
                if (classList[i].classId === data[j].classId) {
                  classList[i] = data[j]
                  break
                }
              }
            }
            this.$nextTick(() => {
              this.toggleSelection(classList)
            })
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    querySearch(queryString, cb) {
      const {schoolList} = this
      const list = schoolList.filter(this.schoolFilter(queryString))
      cb(list)
    },
    schoolFilter(name) {
      return (school) => {
        return (school.schoolName.indexOf(name) !== -1)
      }
    },
    handleSelectionChange(val) {
      this.selectedClassList = val
    },
    toggleSelection(classList) {
      classList.forEach(row => {
        this.$refs.multipleTable.toggleRowSelection(row)
      })
    },
    schoolSave() {
      if (!this.searchId || this.selectedClassList.length === 0) {
        this.$alert('请选择班级', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      const obj = {
        id: this.searchId,
        name: this.searchName,
        searchGradeId: this.searchGradeId,
        classList: this.selectedClassList
      }
      this.$emit('schoolSave', obj)
      this.visible = false
    },
    handleClose() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.bg {
  background-color: rgba(0, 0, 0, .3);
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.wrapper {
  background-color: #fff;
  width: 1100px;
  border-radius: 16px 16px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  .header {
    @include flex(space-between);
    height: 78px;
    padding: 0 28px;

    .icon {
      background-image: url("../../../assets/close.png");
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .container {
    overflow: auto;
    max-height: calc(90vh - 282px);
    padding: 0 28px;
    font-size: 17px;
    position: relative;
    z-index: 90;
  }

  .search-list {
    @include flex(space-between);
    height: 80px;
    padding: 0 28px;
    font-size: 15px;
    color: #999;

    .text {
      margin: 0 30px;
    }

    .search-name {
      flex-shrink: 0;
      width: 340px;
      margin-right: 10px;
    }

    .search-grade {
      flex-shrink: 0;
      width: 100px;
      margin-right: 10px;
    }

    .search-year {
      flex-shrink: 0;
      width: 118px;
    }

    .search-btn {
      padding-left: 28px;
      border-left: 1px solid #E5E5E5;
      margin-left: 42px;

      button {
        width: 100px;
        height: 40px;
        font-size: 15px;
      }
    }
  }

  .foot {
    @include flex;
    height: 84px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    position: relative;
    z-index: 99;

    .btn {
      width: 180px;
      height: 46px;
      margin-right: 28px;
      font-size: 17px;
    }
  }
}

</style>
